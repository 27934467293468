const events = {
  rapidInit: 'rapid:init'
}

const rapidReady = fn => {
  if (window.rapidInstance) {
    fn(window.rapidInstance)
  } else {
    document.addEventListener(
      events.rapidInit,
      () => {
        fn(window.rapidInstance)
      },
      { passive: true, once: true }
    )
  }
}

const ylkStringToObj = (ylkStr = '') => {
  const parseToInt = ['itc', 'cpos', 'pos']
  return ylkStr
    .split(';')
    .filter(Boolean)
    .reduce((acc, kv) => {
      const [k, v] = kv.split(':')
      acc[k] = parseToInt.includes(k) ? parseInt(v, 10) : v
      return acc
    }, {})
}

const ylkObjToString = (ylkObj = {}) =>
  Object.keys(ylkObj)
    .map(key => `${key}:${ylkObj[key]}`)
    .join(';')

module.exports = {
  events,
  rapidReady,
  ylkStringToObj,
  ylkObjToString
}
