import { rapidReady } from '../../../../lib/utils/rapid'

export default (() => {
  class RecipeSearch {
    constructor({ selector }) {
      this.elems = [...document.querySelectorAll(selector)]
      if (!this.elems.length) {
        return
      }
      this.elems.forEach(elem => this.init(elem))
    }

    init(elem) {
      this.addEventListeners(elem)
    }

    addEventListeners(elem) {
      const form = elem.querySelector('.recipe__search-form')
      form.addEventListener('submit', e => {
        e.preventDefault()
        const term = form.search_title.value
        window.location =
          term.length !== 0 ? `/food/search/${encodeURIComponent(term)}/` : '/food/search/'
      })

      window.wafer.ready(() => {
        wafer.on('autocomplete:open', ({ elem: elm }) => {
          if (elm.classList.contains('m-recipe-search--input')) {
            const rapidMod = elm.closest('div[data-rapid="true"]')
            rapidReady(rapid => {
              rapid.refreshModule(rapidMod.id)
            })
          }
        })
      })
    }
  }
  return RecipeSearch
})()
